<div class="feature-field">
    <label class="feature-label" [ngClass]="{ disabled: field.value == null }">
        {{ field.displayName }}
        <span *ngIf="field.required">*</span>
    </label>
    <div class="feature-data image-field-wrapper">
        <div *ngFor="let image of field.value; index as i">
            <a (click)="openLightboxModal(i)" class="pointer">
                <load-img
                    appendClass="{{
                        field.type === FieldType.Signature ? 'attrListItem Signature' : 'attrListItem Image'
                    }}"
                    [imageUrl]="getImageUrl(image, true)"
                    [showOnCondition]="field.value && field.value.length > 0"
                    [authenticate]="false"
                ></load-img>
            </a>
        </div>
    </div>
</div>
