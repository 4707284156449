import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ACCESS_TOKEN_KEY, AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ShareMode } from 'src/app/core/authentication/share-token';
import { User } from 'src/app/shared/user/user';

import { Router } from '@angular/router';
import { HttpErrorCodes } from 'src/app/core/error-page/error-page.component';
import { GspLoggerService } from 'src/app/log-handler.service';
import { License } from '../../user/license';
import { ProjectStreamService } from '../current-project/project-stream.service';
import { CloneUtils } from '../utility/clone-utils';
import { FileUtils } from '../utility/file-utils';
import { CurrentUserService } from './current-user.service';

// Included in script tag inside index.html
declare const aptrinsic: any;

@Injectable({
    providedIn: 'root'
})
export class CurrentUserStreamService {
    stream = new BehaviorSubject<User>(null);

    public currentUserWithRoleStream = new BehaviorSubject<User>(null);

    constructor(
        private currentUserService: CurrentUserService,
        private projectStream: ProjectStreamService,
        private authService: AuthenticationService,
        private gspLoggerService: GspLoggerService,
        private router: Router
    ) {}

    set currentUser(user: User) {
        this.stream.next(user);
        this.loadCurrentUserWithRole();
    }

    get currentUser(): User {
        return this.stream.getValue();
    }

    async initCurrentUser(): Promise<void> {
        const user = await this.currentUserService.getCurrentUser();
        this.currentUser = user;
    }

    async initRegisteredUser(shareMode: ShareMode): Promise<User> {
        const currentUser = this.stream.getValue();
        if (currentUser) {
            return currentUser;
        }

        if (!sessionStorage.getItem(ACCESS_TOKEN_KEY) && shareMode === ShareMode.SIGNED_IN_USER) {
            // Connect does not provide access token in this mode - neither in the URL nor in shares/token response
            if (!!window['Cypress' as any]) {
                this.router.navigate(['error', HttpErrorCodes.UNAUTHORISED]);
            } else {
                this.redirectToLogin();
            }
        } else {
            this.currentUser = await this.getUserBasedOnShareMode(shareMode);
            return this.currentUser;
        }
    }

    async getUserLicenseDetail(): Promise<License> {
        if (this.currentUser.license) {
            return Promise.resolve(this.currentUser.license);
        } else {
            return new Promise((resolve, reject) => {
                this.currentUserService
                    .getLicenseDetail()
                    .then(license => {
                        let user = CloneUtils.cloneDeep(this.currentUser);
                        user.license = license;
                        this.currentUser = user;
                        resolve(license);
                    })
                    .catch(() => {
                        reject(null);
                    });
            });
        }
    }

    async loadBlobbedUserThumbnail(): Promise<void> {
        // need to make sure that url provided can be retrieved as blob url. otherwise, it will be set to null by default.
        if (this.currentUser?.tiduuid) {
            this.currentUser.thumbnail = await FileUtils.httpUrlToBlobUrl(this.currentUser?.thumbnail).catch(
                () => null
            );
        } else {
            // for public user, thumbnail is not set by default
            this.currentUser.thumbnail = null;
        }
    }

    loadCurrentUserWithRole(): User {
        if (!this.currentUser) {
            // add datadog log if user is not set in the stream for some reason - this can be API failure or any other reason
            this.gspLoggerService.error('Failed to load a user in the stream. Current user is set to null.');
            return null;
        }

        this.loadBlobbedUserThumbnail();
        this.projectStream.currentProjectStream.pipe(filter(Boolean), take(1)).subscribe(() => {
            this.projectStream
                .getUserWithRolesForCurrentProject(this.currentUser.id, this.currentUser.tiduuid)
                .then(userWithRole => {
                    // NOTE: Needed to add condition to run unit test successfully as it is not able to find aptrinsic() that was declared in index.html as mentioned in line 15
                    if (typeof aptrinsic !== 'undefined') {
                        // Send user info to Gainsight
                        aptrinsic(
                            'identify',
                            {
                                //User Fields
                                id: userWithRole.tiduuid
                            },
                            {
                                //Account Fields
                                id: this.projectStream.currentProject.id
                            }
                        );
                    }
                    userWithRole.thumbnail = this.currentUser.thumbnail;
                    this.currentUserWithRoleStream.next(userWithRole);
                    return userWithRole;
                });
        });
    }

    private async getUserBasedOnShareMode(shareMode: ShareMode): Promise<User> {
        let user: User;

        if (shareMode === ShareMode.PUBLIC_USER) {
            // create a pseudo 'User' instance - this acts as a public user for shareMaps url.
            // if removed it will cause - TCMAPS-4212 : https://jira.trimble.tools/browse/TCMAPS-4212
            // use User.fromDTO() to avoid exposing error in the console
            user = User.fromDTO(new User());
        } else {
            // SIGNED_IN_USER and PROJECT_USER cases
            user = await this.currentUserService.getCurrentUser();
        }

        return user;
    }

    private async redirectToLogin(): Promise<void> {
        window.location.href = await this.authService.getLoginUrl();
    }
}
