import { Component, Input, OnInit } from '@angular/core';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { FieldType } from 'src/app/shared/template-services/field';

import { ImageSignatureFeatureField } from '../feature-field';

@Component({
    templateUrl: './image-field.component.html',
    selector: 'image-field'
})
export class ImageFieldComponent implements OnInit {
    @Input()
    field: ImageSignatureFeatureField;

    @Input()
    editMode: boolean;

    public FieldType = FieldType;

    constructor(private gallery: Gallery, private lightbox: Lightbox) {}

    ngOnInit(): void {
        const items: GalleryItem[] = this.field.value?.map(
            imageUrl =>
                new ImageItem({
                    src: this.getImageUrl(imageUrl),
                    thumb: this.getImageUrl(imageUrl, true)
                })
        );
        if (items) {
            this.gallery
                .ref(this.field.name, { thumbPosition: 'top', thumb: items.length > 1, counter: items.length > 1 })
                .load(items);
        }
    }

    public openLightboxModal(index: number): void {
        this.lightbox.open(index, this.field.name, { panelClass: 'top' });
    }

    public getImageUrl(image: string, thumbnail: boolean = false): string {
        if (!image) {
            return '';
        } else {
            // TODO: GIM 28/5/2019 - don't access window directly
            image =
                window.location.protocol === 'https:'
                    ? image.replace('http:', 'https:')
                    : image.replace('https:', 'http:');
            return thumbnail ? image + '&thumbnail=true' : image;
        }
    }
}
