import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { NotificationType } from 'src/app/shared/common/components/gsp-notification/gsp-notification.component';
import { CurrentEntitlementStreamService } from 'src/app/shared/common/current-entitlement/current-entitlement-stream.service';
import { EntitlementSKUs } from 'src/app/shared/common/current-entitlement/entitlement';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ModalSize } from 'src/app/shared/common/modal-sizes';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';

@Component({
    selector: 'post-processing-tab',
    templateUrl: './post-processing-tab.component.html'
})
export class PostProcessingTabComponent implements OnInit {
    public NotificationType = NotificationType;
    public ButtonType = ButtonType;
    public ModalSize = ModalSize;

    public toggleLabel = '';
    public workspace: MapWorkspace = null;
    public updateInProgress = false;
    public loading = true;
    public hasEntitlement = false;

    constructor(
        private mapWorkspaceStore: MapWorkspacesStoreService,
        private messaging: MessagingService,
        private translate: TranslationService,
        private router: Router,
        private currentEntitlementStreamService: CurrentEntitlementStreamService
    ) {}

    ngOnInit(): void {
        this.workspace = this.mapWorkspaceStore.getCurrentMapWorkspace();
        this.setToggleLabel();
        this.hasEntitlement = this.currentEntitlementStreamService.hasEntitlement(
            EntitlementSKUs.EARLY_EXPERIENCE_PROGRAM
        );
    }

    onToggled(): void {
        this.updateWorkspace().then(() => this.setToggleLabel());
    }

    onPopupDone(): void {
        if (!this.workspace.hasBounds()) {
            this.mapWorkspaceStore.editMapWorkspaceStream.next(this.workspace);
            this.router.navigate(['mapViewer', { outlets: { centerDialog: 'workspace/details' } }], {
                queryParamsHandling: 'preserve',
                skipLocationChange: true
            });
        }
    }

    onPostProcessingOptionsInit(): void {
        // Triggered after async method in child component
        // Run in next change-detection cycle to avoid ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => (this.loading = false));
    }

    private setToggleLabel(): string {
        this.toggleLabel = this.workspace?.postProcessingOptions?.enabled
            ? this.translate.instant('MapViewer.Positioning.DisableOfflineGNSS', {
                  workspaceName: this.workspace ? this.workspace.name : ''
              })
            : this.translate.instant('MapViewer.Positioning.EnableOfflineGNSS', {
                  workspaceName: this.workspace ? this.workspace.name : ''
              });

        return this.toggleLabel;
    }

    public async updateWorkspace(): Promise<void> {
        this.updateInProgress = true;
        const result = await this.mapWorkspaceStore.updateMapWorkspace(this.workspace);
        if (!result.succeeded) {
            this.messaging.showError(this.translate.instant('MapViewer.Positioning.UpdatedOfflineGNSS.Error'));
        } else {
            this.messaging.showSuccess(this.translate.instant('MapViewer.Positioning.UpdatedOfflineGNSS.Success'));
            this.mapWorkspaceStore.updateMapWorkspaceCache(result.updatedMapWorkspace);
        }
        this.updateInProgress = false;
    }
}
