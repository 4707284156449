<div>
    <post-processing-options
        *ngIf="workspace"
        [disableForLockedWorkspaces]="true"
        [enablePopup]="true"
        [toggleLabel]="toggleLabel"
        [workspace]="workspace"
        [updateInProgress]="updateInProgress"
        (initialized)="onPostProcessingOptionsInit()"
        (popupDone)="onPopupDone()"
        (toggled)="onToggled()"
    ></post-processing-options>
</div>

<div class="footer">
    <img src="assets/images/svg/Trimble-Early-Experience-program.svg" alt="" />
    <div class="description">
        <p>
            {{ 'MapViewer.OfflineGNSS.Description' | translate }}
            <a
                class="default-link default-link-light"
                href="https://help.trimblegeospatial.com/TerraFlex/offline-gnss-corrections.htm"
                target="_blank"
                >{{ 'MapViewer.EEP.Links.Anchor' | translate }}</a
            >.
            <br />
            <br />
            {{ 'MapViewer.OfflineGNSS.Footer' | translate }}
        </p>
    </div>
</div>
